var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        value: _vm.value,
        title: _vm.$t("Supply optimization"),
        persistent: "",
        "fill-width": "",
        maxWidth: 1000,
        width: 1000,
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "me-3",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: { click: _vm.handleSecondaryButtonClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.secondaryButtonText))]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.primaryBtnDisabled,
                      },
                      on: { click: _vm.handlePrimaryButtonClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.primaryButtonText) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "cs-flex",
        { staticClass: "supply-optimization-dialog", attrs: { vertical: "" } },
        [
          _c("div", { staticClass: "text-h6 text-center mb-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("Stock definition")) + " "),
          ]),
          _c(
            "v-stepper",
            {
              staticClass: "supply-optimization-stepper elevation-0",
              attrs: { color: "inherit" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "elevation-0 px-7 py-4" },
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 1, step: "1" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Portions"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 2, step: "2" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("Define purchase lengths")) + " "
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 3, step: "3" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("Optimization parameters")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("cs-unit-field", {
                        ref: "toleranceField",
                        staticClass: "mb-10 mt-5",
                        attrs: {
                          title: _vm.$t("Tolerance"),
                          rules: _vm.toleranceRules,
                          unit: "mm",
                          precision: 0,
                          maxWidth: "110px",
                        },
                        model: {
                          value: _vm.tolerance,
                          callback: function ($$v) {
                            _vm.tolerance = $$v
                          },
                          expression: "tolerance",
                        },
                      }),
                      _c("optimization-dialog-portion-section", {
                        attrs: { checkFullModel: _vm.checkFullModel },
                        on: {
                          onCheckFullModelChange: function ($event) {
                            _vm.checkFullModel = $event
                          },
                        },
                        model: {
                          value: _vm.selectedPortions,
                          callback: function ($$v) {
                            _vm.selectedPortions = $$v
                          },
                          expression: "selectedPortions",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-stepper-content", { attrs: { step: "2" } }, [
                    _c(
                      "div",
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.itemHeaders,
                            items: _vm.supplyOptSummaries,
                            "single-expand": _vm.singleExpand,
                            expanded: _vm.expanded,
                            "item-key": "section",
                            "show-expand": "",
                            "hide-default-header": "",
                          },
                          on: {
                            "update:expanded": function ($event) {
                              _vm.expanded = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function ({ props: { headers } }) {
                                  return [
                                    _c("v-data-table-header", {
                                      staticClass:
                                        "supply-optimization-table-header",
                                      attrs: { headers: headers },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "expanded-item",
                                fn: function ({ headers, item }) {
                                  return [
                                    _c(
                                      "td",
                                      { attrs: { colspan: headers.length } },
                                      [
                                        _c("v-data-table", {
                                          attrs: {
                                            "hide-default-header": "",
                                            headers: _vm.groupHeaders,
                                            dense: "",
                                            items: item.groups,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function ({ on, props }) {
                                                  return [
                                                    _c(
                                                      "v-data-table-header",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "supply-optimization-table-header pa-1",
                                                          },
                                                          "v-data-table-header",
                                                          props,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.length`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({
                                                    attrs: attrsTooltip,
                                                    on: onTooltip,
                                                  }) {
                                                    return [
                                                      _vm.getWarningOnSupplyOptItem(
                                                        item
                                                      )
                                                        ? _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "pr-2",
                                                                  attrs: {
                                                                    color:
                                                                      "warning",
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrsTooltip,
                                                                false
                                                              ),
                                                              onTooltip
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-information-outline"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Too are too short purchase length are present in the list"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("cs-tag-area", {
                                          attrs: {
                                            dense: "",
                                            showMenuIcon: "",
                                            placeholder:
                                              "Type and press Enter to create new length",
                                            items: item.selectableLengths,
                                            draggable: "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleSupplyOptUpdate(
                                                item
                                              )
                                            },
                                            change: function ($event) {
                                              return _vm.onChangeLength(
                                                item,
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.length,
                                            callback: function ($$v) {
                                              _vm.$set(item, "length", $$v)
                                            },
                                            expression: "item.length",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.delete`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "cs-btn",
                                      {
                                        attrs: { icon: "", minWidth: "0" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onClickDelete(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-delete-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }),
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.optimizationParamsHeaders,
                          items: _vm.supplyOptParameters,
                          "single-expand": _vm.singleExpand,
                          expanded: _vm.expanded,
                          "item-key": "section",
                          "show-expand": "",
                          "hide-default-header": "",
                        },
                        on: {
                          "update:expanded": function ($event) {
                            _vm.expanded = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function ({ props: { headers } }) {
                                return [
                                  _c("v-data-table-header", {
                                    staticClass:
                                      "supply-optimization-table-header",
                                    attrs: { headers: headers },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "expanded-item",
                              fn: function ({ headers, item }) {
                                return [
                                  _c(
                                    "td",
                                    { attrs: { colspan: headers.length } },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "hide-default-header": "",
                                          headers:
                                            _vm.optimizationParamsGroupHeaders,
                                          dense: "",
                                          "item-key": "id",
                                          items: item.groups,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function ({
                                                props: { headers },
                                              }) {
                                                return [
                                                  _c("v-data-table-header", {
                                                    staticClass:
                                                      "supply-optimization-table-header pa-1",
                                                    attrs: { headers: headers },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: `item.length`,
                                              fn: function ({ item }) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.value)),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: `item.maxWasteInMM`,
                                              fn: function ({ item }) {
                                                return [
                                                  _c("cs-unit-field", {
                                                    attrs: {
                                                      rules: _vm.wasteRules,
                                                      unit: "mm",
                                                      precision: 0,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleSupplyOptParameterUpdate()
                                                      },
                                                    },
                                                    model: {
                                                      value: item.maxWasteInMm,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "maxWasteInMm",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.maxWasteInMm",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.parameters`,
                              fn: function ({ item }) {
                                return [
                                  _c("cs-combobox", {
                                    attrs: {
                                      multiple: "",
                                      "small-chips": "",
                                      outlined: "",
                                      "hide-details": "",
                                      disabled: "",
                                      "append-icon": "",
                                      value: item.groups.map(
                                        (groupItem) =>
                                          `${groupItem.value} mm (${groupItem.maxWasteInMm} mm)`
                                      ),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    label: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("optimization-progress-dialog", {
        attrs: {
          currentProgressIndex: _vm.currentSupplyOptimizationStageIndex,
          progresses: _vm.getSupplyOptimizationProgresses,
          title: _vm.$t("Supply optimization") + "...",
        },
        model: {
          value: _vm.supplyOptimizationProgressDialog,
          callback: function ($$v) {
            _vm.supplyOptimizationProgressDialog = $$v
          },
          expression: "supplyOptimizationProgressDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }