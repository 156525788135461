var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "supply-optimization-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        justify: "flex-start",
        vertical: "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row pt-7 px-4 pb-5" },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "supply-optimization-title" },
              [
                _vm._v(" " + _vm._s(_vm.$t("Supply")) + " "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", "max-width": "350px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: { size: "16" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-information-outline")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("Start the optimization process."))),
                    ]),
                  ]
                ),
              ],
              1
            ),
            !_vm.supplyLoading && _vm.isEmpty
              ? _c("div", { staticClass: "empty-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "You have not started an optimization process yet."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "cs-btn",
            {
              staticClass: "ml-auto",
              attrs: {
                elevation: "1",
                small: "",
                disabled: _vm.supplyStartBtnDisabled,
                loading: _vm.supplyLoading,
              },
              on: { click: _vm.handleClickShowStartSupply },
            },
            [
              _c("v-icon", [_vm._v("mdi-sync")]),
              _vm._v(" " + _vm._s(_vm.$t("Start"))),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.supplyLoading
        ? [
            !_vm.isEmpty
              ? _c(
                  "div",
                  [
                    _c("cs-select", {
                      staticClass: "pa-4",
                      attrs: {
                        outlined: "",
                        dense: "",
                        autofocus: "",
                        "hide-details": "",
                        "append-icon": "mdi-chevron-down",
                        items: _vm.portionItems,
                      },
                      model: {
                        value: _vm.selectedPortionId,
                        callback: function ($$v) {
                          _vm.selectedPortionId = $$v
                        },
                        expression: "selectedPortionId",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _c(
            "cs-flex",
            { staticClass: "pa-5", attrs: { vertical: "", "fill-width": "" } },
            [_c("v-skeleton-loader", { attrs: { type: "text" } })],
            1
          ),
      _c("v-divider"),
      _c("supply-optimization-dialog", {
        model: {
          value: _vm.showSupplyOptimizationDialog,
          callback: function ($$v) {
            _vm.showSupplyOptimizationDialog = $$v
          },
          expression: "showSupplyOptimizationDialog",
        },
      }),
      !_vm.supplyLoading
        ? [
            _vm.isEmpty
              ? _c("empty-supply", { staticClass: "empty-supply" })
              : _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex pt-4 px-4" },
                      [
                        _c("div", { staticClass: "flex-grow-1" }, [
                          _vm._v(_vm._s(_vm.$t("Results"))),
                        ]),
                        _c(
                          "cs-flex",
                          { staticClass: "supply-optimization-tab-date" },
                          [_vm._v(_vm._s(_vm.supplyOptimizationDate))]
                        ),
                        _c(
                          "cs-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: _vm.handleClickSuppyOptimizationExport,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v("mdi-download"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "cs-flex",
                      {
                        staticClass: "px-4",
                        attrs: {
                          justify: "center",
                          align: "center",
                          gap: "1rem",
                        },
                      },
                      [
                        _c("div", { staticClass: "pt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Filter:"))),
                        ]),
                        _c(
                          "cs-radio-group",
                          {
                            attrs: { row: "", "hide-details": "" },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          },
                          [
                            _c(
                              "cs-flex",
                              {
                                attrs: {
                                  "fill-width": "",
                                  justify: "center",
                                  align: "center",
                                },
                              },
                              [
                                _c("cs-radio", {
                                  attrs: {
                                    label: _vm.$t("By section"),
                                    value: _vm.SectionFilterValue,
                                  },
                                }),
                                _c("cs-radio", {
                                  attrs: {
                                    label: _vm.$t("By material"),
                                    value: _vm.MaterialFilterValue,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("warehouse-optimization-select-tree", {
                      staticClass: "pt-6",
                      attrs: {
                        items: _vm.warehouseOptItems,
                        selectedIds: _vm.selectedIds,
                        selectedColor: _vm.warehouseOptColor,
                      },
                      on: {
                        select: _vm.select,
                        remove: _vm.remove,
                        delete: _vm.onClickDeleteObjectMatchNode,
                      },
                    }),
                    _c("v-divider"),
                    _c("warehouse-optimization-select-tree", {
                      attrs: {
                        items: _vm.supplyOptItems,
                        selectedIds: _vm.selectedIds,
                        selectedColor: _vm.supplyOptColor,
                      },
                      on: { select: _vm.select, remove: _vm.remove },
                    }),
                    _c("v-divider"),
                    _c("warehouse-optimization-select-tree", {
                      attrs: {
                        items: _vm.remainingItems,
                        selectedIds: _vm.selectedIds,
                      },
                      on: { select: _vm.select, remove: _vm.remove },
                    }),
                  ],
                  1
                ),
          ]
        : _c(
            "cs-flex",
            { staticClass: "pa-5", attrs: { vertical: "", "fill-width": "" } },
            [
              _c("v-skeleton-loader", {
                staticClass: "pb-8",
                attrs: { type: "heading" },
              }),
              _c("v-skeleton-loader", {
                staticClass: "pb-5",
                attrs: { type: "sentences" },
              }),
              _c("v-skeleton-loader", { attrs: { type: "paragraph" } }),
            ],
            1
          ),
      _c("cs-warning-dialog", {
        attrs: {
          doNotAskAgain: "",
          twoButton: "",
          submitText: _vm.$t("Delete"),
          width: "380",
          header: "",
          headerText: _vm.$t("Delete"),
          title: _vm.$t("Delete optimization"),
          description: _vm.$t(
            "Are you sure you want to delete? This action is irreversible."
          ),
        },
        on: { submit: _vm.onClickApproveDeleteObjectMatchNode },
        model: {
          value: _vm.showDeleteObjectMatchNodeDialog,
          callback: function ($$v) {
            _vm.showDeleteObjectMatchNodeDialog = $$v
          },
          expression: "showDeleteObjectMatchNodeDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }