
import { getModelByIdSubscribe, getModelFile, SteelspaceModel } from '@/modules/model'
import {
  DefaultTolerance,
  OnStockModel,
  OnStockProject,
  onStockStore,
  OnStockWarehouseOptimization,
  OptimizationHubProgress,
  OptimizationHubResult,
  setOnStockError,
  startSupplyOptimization,
  terminateHub,
} from '@/modules/onstock'
import {
  createSupplyOptimization,
  getLatestSupplyOptimization,
  setSupplyOptimization,
  SupplyDefaultLength,
  supplyStore,
  SupplySummaryLength,
} from '@/modules/supply'
import { modelViewerStore } from '@/store/modelViewer.store'
import {
  CsBtn,
  CsCombobox,
  CsFlex,
  CsSimpleDialog,
  CsTagArea,
  CsUnitField,
} from '@consteel/csuetify'
import { CacheContainer, Get, GetAll, Portion, StructuralMember } from '@consteel/storm'
import Vue from 'vue'
import OptimizationProgressDialog, {
  OptimizationDialogProgress,
} from '../OptimizationProgressDialog.vue'
import OptimizationDialogPortionSection, {
  PortionNode,
} from '../WarehouseOptimizationDialog/OptimizationDialogPortionSection.vue'
import { Model } from '@consteel/smadsteelloader'

interface SupplyOptimizationDialogProgresses {
  initialization: OptimizationDialogProgress
  supplyOptimization: OptimizationDialogProgress
}

interface SupplyOptimizationParameterGroupItem {
  id: string
  value: string
  maxWasteInMm: number
}

interface SupplyOptimizationParameterItem {
  section: string
  groups?: SupplyOptimizationParameterGroupItem[]
}

interface SupplyOptimizationGroupItem {
  id: string
  quantity: number
  length: number
}

interface SupplyOptimizationSummaryItem {
  section: string
  material: string
  groups?: SupplyOptimizationGroupItem[]
  length: string[]
  selectableLengths: string[]
}

interface SupplyOptimizationSummaryTreeNode {
  name: string
  id?: string
  value?: number
  children?: Map<string, SupplyOptimizationSummaryTreeNode>
}

export default Vue.extend({
  name: 'SupplyOptimizationDialog',
  components: {
    CsSimpleDialog,
    CsFlex,
    CsBtn,
    CsTagArea,
    OptimizationProgressDialog,
    OptimizationDialogPortionSection,
    CsUnitField,
    CsCombobox,
  },
  props: {
    value: { type: Boolean, default: false },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      cache: new CacheContainer(),
      filteredOptParameterItems: [] as SupplyOptimizationSummaryItem[],
      selectedPortions: [] as PortionNode[],
      checkFullModel: false,
      expanded: [],
      step: 1,
      tolerance: DefaultTolerance,
      toleranceFieldRef: null as InstanceType<typeof CsUnitField> | null,
      singleExpand: false,
      supplyOptimizationProgressDialog: false,
      currentSupplyOptimizationStageIndex: 0,
      primaryBtnDisabled: false,
      supplyOptParameters: [] as SupplyOptimizationParameterItem[],
      supplyOptSummaries: [] as SupplyOptimizationSummaryItem[],
      supplyOptimizationProgresses: {
        initialization: {
          value: 0,
          text: this.$tc('Inicializálás'),
          details: '',
          indeterminate: true,
          progress: 0,
        },
        supplyOptimization: {
          value: 1,
          text: this.$tc('Beszerzés optimalizálása'),
          details: '',
          progress: 0,
        },
      } as SupplyOptimizationDialogProgresses,
      itemHeaders: [
        {
          text: this.$t('Section'),
          align: 'start',
          sortable: false,
          value: 'section',
        },
        { text: this.$t('Material'), value: 'material', sortable: false },
        { text: this.$t('Purchase lengths (mm)'), sortable: false, value: 'length' },
        { text: '', sortable: false, value: 'delete' },
      ],
      groupHeaders: [
        {
          text: this.$t('Number of required unique length members'),
          value: 'quantity',
          sortable: true,
        },
        { text: this.$t('Length (mm)'), value: 'length', sortable: true },
      ],
    }
  },
  mounted() {
    this.updateSupplySummaries()
  },
  computed: {
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    lastStep(): number {
      return 3
    },
    wasteRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Maximum waste is required.')
          if (value < 0) return this.$tc('Maximum waste cannot be a negative number.')
          return true
        },
      ]
    },
    toleranceRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Tolerance is required.')

          if (value < 0) return this.$tc('Tolerance cannot be a negative number.')
          return true
        },
      ]
    },
    optimizationParamsHeaders() {
      return [
        {
          text: this.$t('Section'),
          align: 'start',
          sortable: false,
          value: 'section',
          width: '25%',
        },
        { text: this.$t('Parameters'), sortable: false, value: 'parameters' },
      ]
    },
    optimizationParamsGroupHeaders() {
      return [
        { text: this.$t('Purchase lengths (mm)'), value: 'length', sortable: false },
        { text: this.$t('Maximum waste') + ' (mm)', value: 'maxWasteInMM', sortable: false },
      ]
    },
    primaryButtonText(): string {
      return this.step === this.lastStep
        ? (this.$t('Search') as string)
        : (this.$t('Next') as string)
    },
    secondaryButtonText(): string {
      return this.step === 1 ? (this.$t('Cancel') as string) : (this.$t('Back') as string)
    },
    warehouseOptimization(): OnStockWarehouseOptimization | null {
      return onStockStore.warehouseOptimization
    },
    getSupplyOptimizationProgresses(): OptimizationDialogProgress[] {
      return Object.keys(this.supplyOptimizationProgresses).map(
        (key) => this.supplyOptimizationProgresses[key]
      )
    },
    model(): SteelspaceModel | null {
      return getModelByIdSubscribe()
    },
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    onstockProject(): OnStockProject | null {
      return onStockStore.project
    },
    defaultLengths(): SupplyDefaultLength[] {
      return supplyStore.defaultLengths
    },
    supplyMaxWaste(): number {
      return supplyStore.defaultWaste?.waste || DefaultTolerance
    },
    structuralMembers(): StructuralMember[] {
      if (!this.modelFile) {
        console.log(`Model file doesn't exist`)
        return []
      }

      return GetAll<StructuralMember>(this.modelFile, StructuralMember, undefined, this.cache)
    },
    getStructuralMembersWithoutWarehouseOpt(): StructuralMember[] {
      const warehouseObjMatches = this.warehouseOptimization?.warehouseObjectMatches

      if (!warehouseObjMatches) return this.structuralMembers

      return this.structuralMembers.filter((structuralMember) => {
        const isMemberInWarehouseOpt = warehouseObjMatches.find(
          (warehouseMatch) => warehouseMatch.smadsteelId == structuralMember.id
        )

        return !isMemberInWarehouseOpt
      })
    },
    buildSupplyOptimizationSummaryTree(): Map<string, SupplyOptimizationSummaryTreeNode> {
      let membersWithoutWarehouseOpt = this.getStructuralMembersWithoutWarehouseOpt

      if (this.selectedPortions.length && !this.checkFullModel) {
        const smadsteelModel = modelViewerStore.model.rawSmadsteelModel

        if (!smadsteelModel) return new Map<string, SupplyOptimizationSummaryTreeNode>()

        const objectsInPortions = this.selectedPortions
          .map((portion) => {
            return Get<Portion>(smadsteelModel, portion.value, Portion)
          })
          .flatMap((item) => item?.items?.map((item) => item.InstanceGuid))

        membersWithoutWarehouseOpt = membersWithoutWarehouseOpt.filter((item) =>
          objectsInPortions.includes(item.id)
        )
      }

      let supplyOptSummaryMap = new Map<string, SupplyOptimizationSummaryTreeNode>()

      for (const member of membersWithoutWarehouseOpt) {
        const sectionData = {
          name: member.section?.name || '',
          children: new Map<string, SupplyOptimizationSummaryTreeNode>(),
        } as SupplyOptimizationSummaryTreeNode

        const materialData = {
          name: member.material?.name || '',
          children: new Map<string, SupplyOptimizationSummaryTreeNode>(),
        } as SupplyOptimizationSummaryTreeNode

        const lengthInMM = Math.round((member.geometry?.length || 0) * 1000)

        const lengthData = {
          id: member.id,
          name: lengthInMM.toString(),
          value: lengthInMM,
          children: new Map<string, SupplyOptimizationSummaryTreeNode>(),
        } as SupplyOptimizationSummaryTreeNode

        const lengthChild = {
          id: member.id,
        } as SupplyOptimizationSummaryTreeNode

        const section = supplyOptSummaryMap.get(sectionData.name)

        if (!section) {
          if (lengthChild.id) lengthData.children?.set(lengthChild.id, lengthChild)

          materialData.children?.set(lengthData.name, lengthData)

          sectionData.children?.set(materialData.name, materialData)

          supplyOptSummaryMap.set(sectionData.name, sectionData)
        } else {
          const material = section.children?.get(materialData.name)

          if (!material) {
            if (lengthChild.id) lengthData.children?.set(lengthChild.id, lengthChild)

            materialData.children?.set(lengthData.name, lengthData)

            section.children?.set(materialData.name, materialData)
          } else {
            const length = material.children?.get(lengthData.name)

            if (!length) {
              if (lengthChild.id) lengthData.children?.set(lengthChild.id, lengthChild)

              material.children?.set(lengthData.name, lengthData)
            } else {
              if (lengthChild.id) length.children?.set(lengthChild.id, lengthChild)
            }
          }
        }
      }

      return supplyOptSummaryMap
    },
    supplyOptParameterItems(): SupplyOptimizationParameterItem[] {
      let supplyParams: SupplyOptimizationParameterItem[] = []

      this.supplyOptSummaries.forEach((summaryItem) => {
        if (this.filteredOptParameterItems.find((item) => item.section === summaryItem.section))
          return

        let groups = this.supplyOptParameters.find(
          (supplyOptParam) => supplyOptParam.section === summaryItem.section
        )?.groups

        if (!groups || groups.length == 0) {
          groups = summaryItem.length.map((length, index) => {
            return {
              id: index.toString() + summaryItem.section,
              value: length,
              maxWasteInMm: this.supplyMaxWaste,
            } as SupplyOptimizationParameterGroupItem
          })
        }

        const supplyParam = {
          section: summaryItem.section,
          groups: groups,
        } as SupplyOptimizationParameterItem

        supplyParams.push(supplyParam)
      })

      return supplyParams
    },
    supplyOptSummaryItems(): SupplyOptimizationSummaryItem[] {
      const supplyOptSummaryTree = this.buildSupplyOptimizationSummaryTree
      const defaultLengths = this.defaultLengths.map((defaultLength) =>
        defaultLength.length.toString()
      )

      let summaryItems: SupplyOptimizationSummaryItem[] = []
      supplyOptSummaryTree.forEach((value) => {
        if (this.filteredOptParameterItems.find((item) => item.section === value.name)) return

        const sectionName = value.name

        value.children?.forEach((value) => {
          const userDefinedLengths = this.supplyOptSummaries.find(
            (supplyOptSummary) =>
              supplyOptSummary.section === sectionName && supplyOptSummary.material === value.name
          )?.length

          let summaryItem = {
            section: sectionName,
            material: value.name,
            length: userDefinedLengths || defaultLengths,
            selectableLengths: defaultLengths,
          } as SupplyOptimizationSummaryItem

          let groups: SupplyOptimizationGroupItem[] = []
          value.children?.forEach((value) => {
            groups.push({
              id: value.id,
              length: value?.value,
              quantity: value.children?.size,
            } as SupplyOptimizationGroupItem)
          })

          groups.sort((groupItemA, groupItemB) => groupItemA.length - groupItemB.length)

          summaryItem.groups = groups
          summaryItems.push(summaryItem)
        })
      })

      summaryItems.sort((summaryItemA, summaryItemB) =>
        summaryItemA.section.toLowerCase().localeCompare(summaryItemB.section.toLowerCase())
      )

      return summaryItems
    },
  },
  methods: {
    getWarningOnSupplyOptItem(item: SupplyOptimizationSummaryItem): boolean {
      return !!item.length?.find(
        (length) =>
          this.isNumeric(length) && !item.groups?.find((group) => group.length < Number(length))
      )
    },
    onClickDelete(item: SupplyOptimizationSummaryItem): void {
      this.filteredOptParameterItems.push(item)
      this.supplyOptSummaries = this.supplyOptSummaryItems
    },
    checkSupplySummaryItems(): void {
      for (const item of this.supplyOptSummaries) {
        if (item.length.length == 0) {
          this.primaryBtnDisabled = true
          return
        }
      }

      this.primaryBtnDisabled = false
    },
    checkPortionSelection(): void {
      const isToleranceNotValid = (this.toleranceFieldRef?.errors.length ?? 0) > 0
      const isPortionNotValid = this.selectedPortions.length === 0 && !this.checkFullModel

      this.primaryBtnDisabled = isToleranceNotValid || isPortionNotValid
    },
    handleSupplyOptUpdate(item: SupplyOptimizationSummaryItem): void {
      for (const item of this.supplyOptSummaries) {
        if (item.length.length == 0) {
          this.primaryBtnDisabled = true
          return
        }
      }

      this.primaryBtnDisabled = false

      const supplyOptParam = this.supplyOptParameters.find(
        (supplyOptParam) => supplyOptParam.section === item.section
      )

      if (!supplyOptParam) return

      let newGroups = [] as SupplyOptimizationParameterGroupItem[]

      item.length.forEach((length, index) => {
        const groupItem = supplyOptParam.groups?.find((groupItem) => groupItem.value === length)

        if (groupItem) newGroups.push(groupItem)
        else {
          newGroups.push({
            id: index.toString() + supplyOptParam.section,
            maxWasteInMm: this.supplyMaxWaste,
            value: length,
          } as SupplyOptimizationParameterGroupItem)
        }
      })

      supplyOptParam.groups = newGroups
    },
    handleSupplyOptParameterUpdate(): void {
      for (const item of this.supplyOptParameters) {
        const unitFieldNotValid = item.groups?.find((groupItem) => {
          return groupItem.maxWasteInMm === null || groupItem.maxWasteInMm < 0
        })

        if (unitFieldNotValid) {
          this.primaryBtnDisabled = true
          return
        }
      }

      this.primaryBtnDisabled = false
    },
    isNumeric(str: string) {
      if (typeof str != 'string') return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    onChangeLength(item: SupplyOptimizationSummaryItem, value: string[]): void {
      item.length = value.filter((length) => this.isNumeric(length))
    },
    async handleProgressResult(result: OptimizationHubResult): Promise<void> {
      if (result.stage == null) return
      else if (result.error) {
        setOnStockError('Progress result error: ' + result.error)
        this.close()
        await terminateHub()
        return
      }

      const relevantProgressKey = Object.keys(this.supplyOptimizationProgresses).find(
        (progressKey) => this.supplyOptimizationProgresses[progressKey].value === result.stage
      )

      if (!relevantProgressKey) {
        console.warn('Relevant progress key not found')
        return
      }

      this.supplyOptimizationProgresses[relevantProgressKey] = {
        ...this.supplyOptimizationProgresses[relevantProgressKey],
        progress: 100,
      }
    },
    async handleProgress(progress: OptimizationHubProgress): Promise<void> {
      if (this.currentSupplyOptimizationStageIndex != progress.currentStageNumber)
        this.currentSupplyOptimizationStageIndex = progress.currentStageNumber

      const relevantProgressKey = Object.keys(this.supplyOptimizationProgresses).find(
        (progressKey) =>
          this.supplyOptimizationProgresses[progressKey].value === progress.currentStageNumber
      )

      if (!relevantProgressKey) {
        console.warn('Relevant progress key not found')
        return
      }

      this.supplyOptimizationProgresses[relevantProgressKey] = {
        ...this.supplyOptimizationProgresses[relevantProgressKey],
        details: progress.stageProgressDetails,
        detailsPortion: progress.portionProgressDetails,
        detailsSolver: progress.solverProgressDetails,

        progress: (progress.stageProgressCurrent / progress.stageProgressMax) * 100,
        progressSolver:
          progress.solverProgressCurrent != null
            ? (progress.solverProgressCurrent / progress.solverProgressMax) * 100
            : null,
        progressPortion:
          progress.portionProgressCurrent != null
            ? (progress.portionProgressCurrent / progress.portionProgressMax) * 100
            : null,
      }
    },
    handleSecondaryButtonClick(): void {
      this.step > 1 ? (this.step -= 1) : this.close()
    },
    async handlePrimaryButtonClick(): Promise<void> {
      if (this.step < this.lastStep) {
        this.step += 1
      } else {
        if (!this.model || !this.onstockModel || !this.onstockProject) return

        this.supplyOptimizationProgressDialog = true

        const modelFile = await getModelFile(
          this.model.id,
          this.model.lastModificationDate ?? Date.now()
        )

        if (modelFile) {
          const supplySummaryItems = this.supplyOptSummaries.map((summaryItem) => {
            const supplyOptParamLengths =
              this.supplyOptParameters.find(
                (supplyOptParam) => supplyOptParam.section === summaryItem.section
              )?.groups || []

            return {
              sectionName: summaryItem.section,
              materialName: summaryItem.material,
              orderedSupplyLengths: supplyOptParamLengths.map(
                (supplyOptParamLenght) =>
                  ({
                    length: Number(supplyOptParamLenght.value),
                    waste: supplyOptParamLenght.maxWasteInMm,
                  } as { length: number; waste: number })
              ),
            } as SupplySummaryLength
          })

          const supplyOptimization = await createSupplyOptimization(
            this.onstockProject.id.toString(),
            this.onstockModel.id.toString(),
            new File([modelFile], 'test.smadsteel'),
            this.checkFullModel
              ? []
              : this.selectedPortions.map((portion) => ({
                  smadsteelId: portion.value,
                  name: portion.label,
                })),
            supplySummaryItems,
            this.tolerance
          )

          this.currentSupplyOptimizationStageIndex = 1
          this.supplyOptimizationProgresses.initialization.progress = 100

          if (!supplyOptimization) {
            setOnStockError(`Supply optimization doesn't exist`)
            this.close()
            await terminateHub()
            return
          }

          await startSupplyOptimization(
            supplyOptimization.id,
            this.handleProgress,
            this.handleProgressResult
          )

          this.currentSupplyOptimizationStageIndex++
        }

        this.supplyOptimizationProgressDialog = false

        const latestSupplyOptimization = await getLatestSupplyOptimization()

        if (latestSupplyOptimization) setSupplyOptimization(latestSupplyOptimization)

        this.close()
      }
    },
    resetStates() {
      this.step = 1
      this.filteredOptParameterItems = []
      this.checkFullModel = false
      this.selectedPortions = []
      this.primaryBtnDisabled = false
      this.tolerance = DefaultTolerance
      this.expanded = []
      this.resetProgressValues()
    },
    close() {
      this.resetStates()
      this.$emit('input', false)
    },
    updateSupplySummaries(): void {
      this.supplyOptSummaries = this.supplyOptSummaryItems
    },
    updateSupplyOptParameters(): void {
      this.supplyOptParameters = this.supplyOptParameterItems
    },
    resetProgressValues(): void {
      Object.keys(this.supplyOptimizationProgresses).forEach((progressKey) => {
        this.supplyOptimizationProgresses[progressKey].details = ''
        this.supplyOptimizationProgresses[progressKey].progress = 0
      })
    },
  },
  watch: {
    value(): void {
      if (this.value) {
        this.$nextTick(() => {
          this.toleranceFieldRef =
            (this.$refs.toleranceField as InstanceType<typeof CsUnitField>) || null
          this.checkPortionSelection()
        })
        this.filteredOptParameterItems = []
        this.updateSupplySummaries()
      }
    },
    checkFullModel(): void {
      this.checkPortionSelection()
      this.filteredOptParameterItems = []
      this.updateSupplySummaries()
    },
    selectedPortions(): void {
      this.checkPortionSelection()
      this.filteredOptParameterItems = []
      this.updateSupplySummaries()
    },
    supplyOptSummaries(): void {
      this.updateSupplyOptParameters()
    },
    defaultLengths(): void {
      this.supplyOptSummaries = []
      this.supplyOptParameters = []
      this.updateSupplySummaries()
      this.updateSupplyOptParameters()
    },
    supplyMaxWaste(): void {
      this.supplyOptParameters = []
      this.updateSupplyOptParameters()
    },
    step(): void {
      switch (this.step) {
        case 1:
          this.checkPortionSelection()
          break
        case 2:
          this.checkSupplySummaryItems()
          break
        case 3:
          this.updateSupplyOptParameters()
          break
      }
    },
    tolerance(): void {
      this.$nextTick(() => {
        this.toleranceFieldRef =
          (this.$refs.toleranceField as InstanceType<typeof CsUnitField>) || null
        this.checkPortionSelection()
      })
    },
    modelFile(): void {
      this.cache.Clear()
    },
  },
})
