
import { getPortionFolders } from '@/modules/storm/storm.service'
import {
  modelViewerStore,
  removeSelection,
  setIsolation,
  setIsolationEnabled,
  setIsolationPortionIds,
  setUnselectedMembersToTransparentOptionDisabled,
} from '@/store/modelViewer.store'
import { CsFlex, CsTreeview } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { PortionFolder } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import Vue from 'vue'

type PortionNode = {
  name: string
  id: guid
  objectItems?: guid[]
  disabled?: boolean
  children?: PortionNode[]
}

export default Vue.extend({
  name: 'PortionTab',
  components: {
    CsFlex,
    CsTreeview,
  },
  data() {
    return {
      selected: [] as Array<PortionNode>,
    }
  },
  computed: {
    isolationEnabled(): boolean {
      return modelViewerStore.isolationEnabled
    },
    model(): Model | null | undefined {
      return modelViewerStore.model.rawSmadsteelModel
    },
    portions(): PortionNode[] {
      const portionFolders = getPortionFolders(this.model).filter(
        (portionFolder) => portionFolder.isRoot
      )

      return this.buildPortionTree(portionFolders)
    },
  },
  watch: {
    model() {
      setIsolationEnabled(false, true)
      setIsolation([])
      setIsolationPortionIds([])
    },
  },
  methods: {
    buildPortionTree(portionFolders: PortionFolder[]): PortionNode[] {
      return portionFolders.map((portionFolder): PortionNode => {
        return {
          name: portionFolder.name || '',
          id: portionFolder.id || '',
          disabled: !this.isolationEnabled,
          children: [
            ...this.buildPortionTree(portionFolder.portionFolders),
            ...portionFolder.portions.map((portion) => ({
              name: portion.name || '',
              id: portion.id || '',
              children: [],
              disabled: !this.isolationEnabled,
              objectItems: portion.items?.map((item) => item.InstanceGuid) || [],
            })),
          ],
        }
      })
    },
    handleTogglePortions(event: any): void {
      removeSelection()
      const value = event.target.checked
      setIsolationEnabled(value)
      setUnselectedMembersToTransparentOptionDisabled(value)
      setIsolation(value ? modelViewerStore.isolatedPortiosIds : [])
    },
    handleChangeSelectedPortion(newVal: PortionNode[]): void {
      removeSelection()
      const isolatedModelObjectIds = [] as string[]
      newVal.forEach((selectedPortion) => {
        if (selectedPortion && selectedPortion.objectItems)
          isolatedModelObjectIds.push(...selectedPortion.objectItems)
      })
      setIsolationPortionIds(isolatedModelObjectIds)
      setIsolation(isolatedModelObjectIds)
    },
  },
})
