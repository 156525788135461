
import {
  OnStockModel,
  OnStockProject,
  SummaryType,
  SupplyObjectMatch,
  SupplyOptimization,
  WarehouseObjectMatch,
  WarehouseOptimization,
} from '@/modules/graphql/graphql.types'
import {
  onStockStore,
  removeSelectionAndIsolation,
  setSelectionAndIsolation,
} from '@/modules/onstock'
import {
  decreaseSupplyProgress,
  exportSupplyOptimization,
  getSupplyLoading,
  getSupplyOptimization,
  increaseSupplyProgress,
  setSupplyInstanceColors,
  setSupplyOptimization,
  supplyOptColor,
  supplyStore,
} from '@/modules/supply'
import {
  buildRemainingItemsTree,
  setFetchedWarehouseOptimization,
  warehouseOptColor,
  warehouseOptimizationItems,
  warehouseStore,
} from '@/modules/warehouse'
import {
  getCompareStateBasedOnFadingLevel,
  ModelStateNumber,
  modelViewerStore,
  setInstanceColor,
} from '@/store/modelViewer.store'
import { CsBtn, CsFlex, CsRadio, CsRadioGroup, CsSelect, CsWarningDialog } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { StructuralMember } from '@consteel/storm'
import { getPortion, getStructuralMembers } from '@/modules/storm/storm.service'
import { Color3 } from '@consteel/straw'
import Vue from 'vue'
import SupplyOptimizationDialog from '../Dialogs/SupplyOptimizationDialog/SupplyOptimizationDialog.vue'
import EmptySupply from './EmptySupply.vue'
import WarehouseOptimizationSelectTree, {
  OptimizationTreeTooltipType,
  WarehouseOptimizationTreeNode,
} from './WarehouseOptimizationSelectTree.vue'

interface SupplyOptimizationTreeNode {
  id: string
  name: string
  value: number
  count?: number
  hideProgress?: boolean
  children?: Map<string, SupplyOptimizationTreeNode>
  addSeparator?: boolean
}

interface SupplyOptimizationPortionItem {
  text: string | undefined
  value: string | null | undefined
}

export default Vue.extend({
  name: 'CommentTab',
  components: {
    CsFlex,
    EmptySupply,
    SupplyOptimizationDialog,
    CsBtn,
    CsSelect,
    CsRadio,
    CsRadioGroup,
    WarehouseOptimizationSelectTree,
    CsWarningDialog,
  },
  data() {
    return {
      showSupplyOptimizationDialog: false,
      selectedPortionId: null as string | null,
      filter: SummaryType.Section as SummaryType,
      warehouseOptItems: [] as WarehouseOptimizationTreeNode[],
      supplyOptItems: [] as WarehouseOptimizationTreeNode[],
      remainingItems: [] as WarehouseOptimizationTreeNode[],
      showDeleteObjectMatchNodeDialog: false,
      deletingObjectMatchNode: null as WarehouseOptimizationTreeNode | null,
      doNotAskAgainDelete: false,
    }
  },
  async mounted() {
    increaseSupplyProgress()

    await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    await this.setFetchedSupplyOptimization()
    if (!this.isEmpty) this.setSupplyOptimizationTabTrees()

    decreaseSupplyProgress()
  },
  computed: {
    supplyOptColor: supplyOptColor,
    warehouseOptColor: warehouseOptColor,
    supplyStartBtnDisabled(): boolean {
      return this.supplyLoading || !!this.$route.params.historyId
    },
    portionItems(): SupplyOptimizationPortionItem[] {
      let portionItems: SupplyOptimizationPortionItem[] = []

      if (this.warehouseOptimization) {
        portionItems = this.warehouseOptimization.warehouseOptimizationPortions.map(
          (portion) =>
            ({
              text: portion.portionName,
              value: portion.portionSmadsteelId,
            } as SupplyOptimizationPortionItem)
        )
      }

      if (this.supplyOptimization) {
        this.supplyOptimization.optimizationPortions.forEach((portion) => {
          const isPortionAlreadyExist = portionItems.find(
            (portionItem) => portionItem.value === portion.portionSmadsteelId
          )

          if (!isPortionAlreadyExist) {
            portionItems.push({
              text: portion.portionName,
              value: portion.portionSmadsteelId,
            } as SupplyOptimizationPortionItem)
          }
        })
      }

      return [...portionItems, { text: this.$t('Full model') as string, value: null }]
    },
    supplyLoading(): boolean {
      return getSupplyLoading()
    },
    model(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    isEmpty() {
      return !supplyStore.supplyOptimization && !supplyStore.supplyOptimizationSecondary
    },
    SectionFilterValue(): SummaryType {
      return SummaryType.Section
    },
    MaterialFilterValue(): SummaryType {
      return SummaryType.Material
    },
    supplyOptimization(): SupplyOptimization | null {
      return supplyStore.supplyOptimization
    },
    supplyOptimizationSecondary(): SupplyOptimization | null {
      return supplyStore.supplyOptimizationSecondary
    },
    warehouseOptimization(): WarehouseOptimization | null {
      return warehouseStore.warehouseOptimization
    },
    warehouseOptimizationSecondary(): WarehouseOptimization | null {
      return warehouseStore.warehouseOptimizationSecondary
    },
    supplyOptimizationDate(): string {
      let date = new Date()

      if (this.isCompare && this.supplyOptimization && this.supplyOptimizationSecondary) {
        date =
          getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
            ? this.supplyOptimization.creationDate
            : this.supplyOptimizationSecondary.creationDate
      } else if (this.supplyOptimization) {
        date = this.supplyOptimization.creationDate
      }

      return Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    },
    isCompare(): boolean {
      return modelViewerStore.activeMainTab == 1
    },
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    modelFileSecondary(): Model | undefined | null {
      return modelViewerStore.secondaryModel.rawSmadsteelModel
    },
    selectedIds(): string[] {
      return modelViewerStore.selectedIds
    },
    onStockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    onstockProject(): OnStockProject | null {
      return onStockStore.project
    },
    structuralMembers(): StructuralMember[] {
      return getStructuralMembers(this.model)
    },
    getMembersBasedOnPortionAndWithoutWarehouseOpt(): StructuralMember[] {
      let membersBasedOnPortionAndWithoutWarehouseOpt = this.structuralMembers

      // filter out portion related members
      const smadsteelModel = modelViewerStore.model.rawSmadsteelModel
      if (smadsteelModel && this.selectedPortionId) {
        const portion = getPortion(smadsteelModel, this.selectedPortionId)

        if (portion) {
          const objectsInPortions = portion.items?.map((item) => item.InstanceGuid)

          if (objectsInPortions) {
            membersBasedOnPortionAndWithoutWarehouseOpt =
              membersBasedOnPortionAndWithoutWarehouseOpt.filter(
                (item) => item.id && objectsInPortions.includes(item.id)
              )
          }
        }
      }

      // filter out portion members which do not belong to warehose opt
      return membersBasedOnPortionAndWithoutWarehouseOpt.filter((structuralMember) => {
        const isMemberInWarehouseOpt = this.getWarehouseOptMatchesBasedOnPortion.find(
          (warehouseMatch) => warehouseMatch.smadsteelId == structuralMember.id
        )

        return !isMemberInWarehouseOpt
      })
    },
    getWarehouseOptMatchesBasedOnPortion(): WarehouseObjectMatch[] {
      if (!this.warehouseOptimization) return []

      const objectMatches = this.selectedPortionId
        ? this.warehouseOptimization.warehouseOptimizationPortions.find(
            (portion) => portion.portionSmadsteelId == this.selectedPortionId
          )?.warehouseObjectMatches
        : this.warehouseOptimization.warehouseObjectMatches

      if (!objectMatches) return []

      return objectMatches
    },
    getSupplyOptMatchesBasedOnPortion(): SupplyObjectMatch[] {
      if (!this.supplyOptimization) return []

      const objectMatches = this.selectedPortionId
        ? this.supplyOptimization.optimizationPortions.find(
            (portion) => portion.portionSmadsteelId == this.selectedPortionId
          )?.supplyObjectMatches
        : this.supplyOptimization.objectMatches

      if (!objectMatches) return []

      return objectMatches
    },
  },
  methods: {
    async onClickDeleteObjectMatchNode(node: WarehouseOptimizationTreeNode) {
      this.deletingObjectMatchNode = node
      if (this.doNotAskAgainDelete && node.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(node.objectMatchId)
      } else {
        this.showDeleteObjectMatchNodeDialog = true
      }
    },
    async onClickApproveDeleteObjectMatchNode(doNotAskAgain: boolean) {
      this.doNotAskAgainDelete = doNotAskAgain
      this.showDeleteObjectMatchNodeDialog = false
      if (this.deletingObjectMatchNode?.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(
          this.deletingObjectMatchNode.objectMatchId
        )
      }
      this.deletingObjectMatchNode = null
    },
    setSupplyOptimizationTabTrees(): void {
      increaseSupplyProgress()

      let instanceColors: { id: string; color: Color3 }[] = []

      // Warehouse Optimization Tree
      const warehouseOptItems = warehouseOptimizationItems(
        this.warehouseOptimization,
        this.warehouseOptimizationSecondary,
        this.modelFile || null,
        this.modelFileSecondary || null,
        this.filter,
        this.isCompare,
        this.selectedPortionId
      )

      // remove sections that do not have children
      let filteredSections: WarehouseOptimizationTreeNode[] = []
      warehouseOptItems.forEach((section: WarehouseOptimizationTreeNode) => {
        const isSectionHasChild = section.children && section.children.length > 0

        if (isSectionHasChild) filteredSections.push(section)
      })

      this.warehouseOptItems = [
        {
          id: 'warehouseOptItems',
          name: this.$t('From warehouse'),
          value: 0,
          hideProgress: true,
          children: filteredSections,
          afterIcon: { name: 'mdi-square', color: warehouseOptColor(), size: 16 },
        },
      ] as WarehouseOptimizationTreeNode[]

      instanceColors.push(
        ...this.getWarehouseOptMatchesBasedOnPortion.map((warehouseMatch) => {
          return {
            id: warehouseMatch.smadsteelId,
            color: Color3.FromHexString(warehouseOptColor()),
          } as {
            id: string
            color: Color3
          }
        })
      )

      let remainingMembers = this.getMembersBasedOnPortionAndWithoutWarehouseOpt.slice()

      // Supply Optimization Tree
      const supplyOptMap: Map<string, SupplyOptimizationTreeNode> =
        this.buildSupplyOptimizationTree(this.filter)
      let supplyOptTree: WarehouseOptimizationTreeNode[] = []

      supplyOptMap.forEach((sectionNode) => {
        let section = {
          id: sectionNode.id,
          name: sectionNode.name,
          value: 0,
          hideProgress: true,
          children: [],
        } as WarehouseOptimizationTreeNode

        sectionNode.children?.forEach((lengthNode) => {
          let length = {
            id: lengthNode.id,
            name: lengthNode.name + ' mm',
            value: (lengthNode.count || 0) * lengthNode.value,
            quantity: lengthNode.count,
            children: [],
            tooltipType: OptimizationTreeTooltipType.SupplyOpt,
          } as WarehouseOptimizationTreeNode

          const lengthChildrenCnt = lengthNode.children?.size || 0
          let childIdx = 0
          let matchLengthsSum = 0

          lengthNode.children?.forEach((memberNode) => {
            const relevantSmadsteelObject = remainingMembers.find(
              (member) => member.id === memberNode.name
            )

            matchLengthsSum += (relevantSmadsteelObject?.geometry?.length || 0) * 1000
            const addUnusedValue =
              (lengthNode.count || 0) > 1 && memberNode.addSeparator
                ? Math.round(lengthNode.value - matchLengthsSum)
                : undefined

            let member = {
              id: relevantSmadsteelObject?.id ?? 'Other',
              name: relevantSmadsteelObject?.name ?? 'N/A',
              value: Math.round((relevantSmadsteelObject?.geometry?.length || 0) * 1000),
              addSeparator: childIdx !== lengthChildrenCnt - 1 ? memberNode.addSeparator : false,
              addUnusedValue: addUnusedValue,
            } as WarehouseOptimizationTreeNode

            if (memberNode.addSeparator) matchLengthsSum = 0

            if (relevantSmadsteelObject?.id)
              instanceColors.push({
                id: relevantSmadsteelObject.id,
                color: Color3.FromHexString(supplyOptColor()),
              })

            if (relevantSmadsteelObject)
              remainingMembers.splice(remainingMembers.indexOf(relevantSmadsteelObject), 1)

            length.children?.push(member)

            childIdx++
          })

          section.children?.push(length)
        })

        supplyOptTree.push(section)
      })

      this.supplyOptItems = [
        {
          id: 'supplyOptItems',
          name: this.$t('From supply'),
          value: 0,
          hideProgress: true,
          children: supplyOptTree,
          afterIcon: { name: 'mdi-square', color: supplyOptColor(), size: 16 },
        },
      ] as WarehouseOptimizationTreeNode[]

      // Remaining Items Tree
      const remainingItemsMap = buildRemainingItemsTree(remainingMembers, this.filter)

      let remainingItemsTree: WarehouseOptimizationTreeNode[] = []
      remainingItemsMap.forEach((value) => remainingItemsTree.push(value))

      this.remainingItems = [
        {
          id: 'remainingItems',
          name: this.$t('Remainder'),
          value: 0,
          hideProgress: true,
          children: remainingItemsTree,
        },
      ] as WarehouseOptimizationTreeNode[]

      setSupplyInstanceColors([...instanceColors])
      setInstanceColor(...instanceColors)

      decreaseSupplyProgress()
    },
    buildSupplyOptimizationTree(filter: SummaryType): Map<string, SupplyOptimizationTreeNode> {
      let supplyOptMap = new Map<string, SupplyOptimizationTreeNode>()
      const isFilterSection = filter === SummaryType.Section

      for (const supplyObjMatch of this.getSupplyOptMatchesBasedOnPortion) {
        const supplySumItem = supplyObjMatch.supplyLength.supplySummaryItem
        const itemName = isFilterSection ? supplySumItem.sectionName : supplySumItem.materialName

        const itemData = {
          id: supplySumItem.id.toString(),
          name: itemName,
          value: 0,
          hideProgress: true,
          children: new Map<string, SupplyOptimizationTreeNode>(),
        } as SupplyOptimizationTreeNode

        const lengthData = {
          id: supplyObjMatch.supplyLength.id.toString(),
          name: supplyObjMatch.supplyLength.length.toString(),
          value: supplyObjMatch.supplyLength.length,
          count: 0,
          children: new Map<string, SupplyOptimizationTreeNode>(),
        } as SupplyOptimizationTreeNode

        const memberChildren = supplyObjMatch.memberSmadsteelIds.map((memberSmadsteelId, index) => {
          return {
            id: memberSmadsteelId,
            name: memberSmadsteelId,
            value: 0,
            addSeparator: index === supplyObjMatch.memberSmadsteelIds.length - 1,
          } as SupplyOptimizationTreeNode
        })

        const section = supplyOptMap.get(itemName)

        if (!section) {
          memberChildren.forEach((memberChild) =>
            lengthData.children?.set(memberChild.name, memberChild)
          )

          itemData.children = new Map<string, SupplyOptimizationTreeNode>([
            [lengthData.name, { ...lengthData, count: 1 }],
          ])

          supplyOptMap.set(itemName, itemData)
        } else {
          const length = section.children?.get(lengthData.name)

          if (!length) {
            memberChildren.forEach((memberChild) =>
              lengthData.children?.set(memberChild.name, memberChild)
            )

            section.children?.set(lengthData.name, {
              ...lengthData,
              count: 1,
            })
          } else {
            memberChildren.forEach((memberChild) =>
              length.children?.set(memberChild.name, memberChild)
            )

            if (length.count) length.count++
          }
        }
      }

      return supplyOptMap
    },
    async setFetchedSupplyOptimization(): Promise<void> {
      if (this.onStockModel && this.onstockProject) {
        if (this.isCompare) return

        const relevantSupplyOptimization = await getSupplyOptimization(this.$route.params.historyId)

        setSupplyOptimization(relevantSupplyOptimization || null)
      }
    },
    handleClickShowStartSupply(): void {
      this.showSupplyOptimizationDialog = true
    },
    async handleClickSuppyOptimizationExport(): Promise<void> {
      if (!this.onstockProject) return
      if (!this.onStockModel) return
      if (!this.supplyOptimization) return

      await exportSupplyOptimization(
        this.onstockProject.id.toString(),
        this.onStockModel.id.toString(),
        this.supplyOptimization.id.toString()
      )
    },
    select: setSelectionAndIsolation,
    remove: removeSelectionAndIsolation,
  },
  watch: {
    selectedPortionId(): void {
      this.setSupplyOptimizationTabTrees()
    },
    filter(): void {
      this.setSupplyOptimizationTabTrees()
    },
    supplyOptimization(): void {
      this.setSupplyOptimizationTabTrees()
      this.selectedPortionId =
        this.supplyOptimization?.optimizationPortions[0]?.portionSmadsteelId || null
    },
    warehouseOptimization(): void {
      this.setSupplyOptimizationTabTrees()
    },
  },
})
