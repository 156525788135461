
import PortionSvg from '@/assets/Portion.svg.vue'
import ArrowsSvg from '@/assets/viewer/Arrows.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import ChatWithBadge from '@/assets/viewer/ChatWithBadge.vue'
import EyeSvg from '@/assets/viewer/Eye.svg.vue'
import HistorySvg from '@/assets/viewer/History.svg.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import CubeLoader from '@/components/Loaders/CubeLoader.vue'
import { commentStore, fetchComments, getCommentLoading } from '@/modules/comment'
import {
  HistoryItem,
  createHistoryItem,
  deleteHistoryItem,
  downloadHistoryItem,
  editHistoryItem,
  getHistoryLoading,
  historyStore,
  resetHistoryError,
  restoreHistoryItem,
  fetchModelHistoryItemsById,
} from '@/modules/history'
import { SteelspaceModel, modelStore } from '@/modules/model'
import {
  createOnStockHistoryItem,
  fetchOnStockModelBySteelspaceId,
  fetchOnStockUsers,
  getOnstockLoading,
  onStockStore,
} from '@/modules/onstock'
import {
  CommentSelectType,
  cameraMode,
  clearView,
  deleteLabels,
  drawModelView,
  getGizmoColorsBasedOnTheme,
  isometricCamera,
  modelViewerStore,
  removeActiveLoad,
  resetCamera,
  setCameraMode,
  setCommentFocusOnObjectOption,
  setCommentShowCreateCommentOption,
  setFloatingComments,
  setGuiObjects,
  setLabelShowAddLabelOption,
  setRenderContext,
  toggleComments,
  toggleLabels,
  setInstanceColor,
  resetInstanceColor,
  setUnselectedMembersToTransparentOption,
  setIsolation,
  setMoveCameraToSelectionOption,
} from '@/store/modelViewer.store'
import { CsBreadcrumbs, CsBtn, CsBtnToggle, CsCommentInput, CsFlex } from '@consteel/csuetify'
import { RenderContext } from '@consteel/straw'
import Vue from 'vue'
import SupplySvg from '../../assets/viewer/Supply.svg.vue'
import WarehouseSvg from '../../assets/viewer/Warehouse.svg.vue'
import { FloatingComment, FloatingCommentInput, FloatingLabel } from '../../types'
import CommentTab from './CommentTab.vue'
import FloatingCommentVue from './Comments/FloatingComment.vue'
import FloatingCommentInputVue from './Comments/FloatingCommentInput.vue'
import GlobalCommentInput from './Comments/GlobalCommentInput.vue'
import DisplayTab from './DisplayTab.vue'
import FailedToLoadModel from './FailedToLoadModel.vue'
import FilterTab from './FilterTab.vue'
import HistoryTab from './HistoryTab.vue'
import {
  DefaultLabel,
  LineLoadLabel,
  LoadTransferSurfaceLabel,
  NodalLoadLabel,
  StructuralMemberLabel,
  StructuralPlateLabel,
  SupportPointLabel,
  SurfaceLoadLabel,
} from './Labels'
import LoadTab from './LoadTab.vue'
import WarehouseOptimizationTab from './WarehouseOptimizationTab.vue'
import SupplyOptimizationTab from './SupplyOptimizationTab.vue'
import {
  getSupplyOptimization,
  setSupplyOptimization,
  getSupplyLoading,
  supplyStore,
} from '@/modules/supply'
import { authStore } from '@/modules/auth'
import PortionTab from './PortionTab.vue'
import { warehouseStore } from '@/modules/warehouse/warehouse.store'
import { OnStockModel, OnStockProject, OnStockUser } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'ModelViewerTab',
  components: {
    GlobalCommentInput,
    FloatingCommentVue,
    FloatingCommentInputVue,
    CubeLoader,
    CsBreadcrumbs,
    CsBtn,
    CsBtnToggle,
    CsFlex,
    DefaultLabel,
    FilterTab,
    FloatingTabs,
    LineLoadLabel,
    LoadTransferSurfaceLabel,
    NodalLoadLabel,
    StructuralMemberLabel,
    StructuralPlateLabel,
    SupportPointLabel,
    SurfaceLoadLabel,
    HistoryTab,
    FailedToLoadModel,
    WarehouseOptimizationTab,
    CsCommentInput,
  },
  props: {
    breadcrumbItems: {
      type: Array as () => Array<{
        text: string
        disabled: boolean
      }>,
    },
    floatingLabels: {
      type: Array as () => FloatingLabel[],
    },
    activeLoadId: String,
    model: Object as () => SteelspaceModel | null,
    historyItems: Array as () => HistoryItem[],
  },
  data() {
    return {
      selectedHistoryItemId: null as string | null,
      secondarySelectedHistoryItemId: null as string | null,
      labelId: 0,
      compareVersionIsActive: false,
      // globalCommentActive: true,
    }
  },
  computed: {
    historyModifyDisabled(): boolean {
      return (
        this.currModelIsPublic ||
        (this.model?.originUserData.email !== this.currentUser?.email &&
          !this.model?.canDownloadModel.find(
            (userData) => userData.email === this.currentUser?.email
          ))
      )
    },
    currentUser(): OnStockUser | null {
      return authStore.currentOnStockUser
    },
    onstockProject(): OnStockProject | null {
      return onStockStore.project
    },
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    numberOfUnreadComments(): number {
      let unreadComments = 0
      commentStore.comments.forEach((comment) => {
        const isCommentUnreadByUser = commentStore.unreads.find(
          (unread) => unread.commentId === comment.id
        )
        if (isCommentUnreadByUser) unreadComments++
      })
      return unreadComments
    },
    floatingCommentInputs(): FloatingCommentInput[] {
      return modelViewerStore.floatingCommentInputs
    },
    commentSelectType(): CommentSelectType {
      return modelViewerStore.commentSelectType
    },
    floatingComments(): FloatingComment[] {
      return modelViewerStore.floatingComments
    },
    getModelId(): string {
      return (this.model?.isOrigin ? this.$route.params.modelId : this.model?.originModelId) || ''
    },
    labelVisibility(): boolean {
      return modelViewerStore.labelVisibility
    },
    viewControlVisibility(): boolean {
      return modelViewerStore.viewControlVisibility
    },
    cantLoad(): boolean {
      return modelViewerStore.cantLoad
    },
    unselectedMembersToTransparentOption(): boolean {
      return modelViewerStore.unselectedMembersToTransparentOption
    },
    commentLoading(): boolean {
      return getCommentLoading()
    },
    onstockLoading(): boolean {
      return getOnstockLoading()
    },
    supplyLoading(): boolean {
      return getSupplyLoading()
    },
    errorMessage(): string {
      return modelViewerStore.errorMessage
    },
    loading(): boolean {
      return (
        getHistoryLoading() ||
        modelStore.loading ||
        this.onstockLoading ||
        this.commentLoading ||
        this.supplyLoading
      )
    },
    modelLoading(): boolean {
      return modelViewerStore.currentViewLoading
    },
    currModelIsPublic(): boolean {
      return !!this.model?.isPublic
    },
    currModelIsShared(): boolean {
      return !this.model?.isOrigin
    },
    selectedItem(): HistoryItem | SteelspaceModel | undefined | null {
      const historyItem = this.historyItems.find((item) => item.id === this.selectedHistoryItemId)

      if (historyItem) {
        return historyItem
      }

      return this.model
    },
    selectedCameraMode: {
      get(): cameraMode {
        return modelViewerStore.cameraMode
      },
      set(newVal: cameraMode) {
        setCameraMode(newVal)
      },
    },
    secondaryTabs(): Array<Record<string, unknown>> {
      const tabs = [
        {
          icon: HistorySvg,
          text: this.$t('History'),
          content: HistoryTab,
          disabled: this.loading,
          props: {
            value: this.selectedHistoryItemId,
            secondarySelected: this.secondarySelectedHistoryItemId,
            model: this.model,
            items: this.historyItems,
            errorMessage: historyStore.historyErrorMessage,
            errorTitle: historyStore.historyErrorTitle,
            errorIcon: historyStore.historyErrorIcon,
            errorIconColor: historyStore.historyErrorIconColor,
            loading: this.loading,
            hideEdit: this.historyModifyDisabled,
            hideCreate: this.historyModifyDisabled,
            hideRestore: this.historyModifyDisabled,
            hideDelete: this.historyModifyDisabled,
            selectable: true,
            selectToCompare: this.compareVersionIsActive,
            compareVersionIsActive: this.compareVersionIsActive,
          },
          listeners: {
            input: this.handleHistoryItemSelect,
            download: downloadHistoryItem,
            restore: restoreHistoryItem,
            create: this.handleHistoryItemCreate,
            edit: editHistoryItem,
            errorCancel: resetHistoryError,
            clickCompareVersions: this.handleClickCompareVersions,
            selectSecondary: this.handleHistoryItemSelectSecondary,
            delete: deleteHistoryItem,
          },
        },
        {
          icon: BoxSvg,
          text: this.$t('Selection'),
          content: FilterTab,
          disabled: this.loading,
        },
        {
          icon: ArrowsSvg,
          text: this.$t('Loads'),
          content: LoadTab,
          disabled: this.loading,
        },
        {
          icon: EyeSvg,
          text: this.$t('Display'),
          content: DisplayTab,
          disabled: this.loading || this.modelLoading,
          listeners: {
            toggleLabels,
            toggleComments,
            setLabelShowAddLabelOption,
            setCommentShowCreateCommentOption,
            setCommentFocusOnObjectOption,
            setUnselectedMembersToTransparentOption,
            setMoveCameraToSelectionOption,
          },
        },
        {
          icon: WarehouseSvg,
          text: this.$t('Warehouse'),
          content: WarehouseOptimizationTab,
          disabled: this.loading,
        },
        {
          icon: SupplySvg,
          text: this.$t('Supply'),
          content: SupplyOptimizationTab,
          disabled: this.loading,
        },
        {
          icon: ChatWithBadge,
          text: this.$t('Comments'),
          disabled: this.loading,
          iconProps: {
            badgeContent: this.numberOfUnreadComments,
          },
          content: CommentTab,
          props: {
            model: this.model,
            numberOfUnreadComments: this.numberOfUnreadComments,
          },
        },
        {
          icon: PortionSvg,
          text: this.$t('Portions'),
          content: PortionTab,
          disabled: this.loading || this.modelLoading,
        },
      ]

      return tabs
    },
    isTransparentSelectionEnabled(): boolean {
      return (
        modelViewerStore.unselectedMembersToTransparentOption &&
        !modelViewerStore.unselectedMembersToTransparentOptionDisabled
      )
    },
  },
  methods: {
    handleTabIconClick(tabIdx: number) {
      resetInstanceColor()

      if (tabIdx === 5 && supplyStore.supplyInstanceColors.length > 0) {
        setInstanceColor(...supplyStore.supplyInstanceColors) // supply opt
      } else if (tabIdx === 4 && warehouseStore.warehouseInstanceColors.length > 0) {
        setInstanceColor(...warehouseStore.warehouseInstanceColors) // warehouse opt
      }
    },
    setFocusToCanvas() {
      if (!modelViewerStore.cantLoad) {
        ;(this.$refs.canvas as HTMLCanvasElement).focus()
      }
    },
    async handleHistoryItemSelectSecondary(historyItemId: string): Promise<void> {
      this.secondarySelectedHistoryItemId = historyItemId
      this.$emit('navigateToCompare', {
        current: this.selectedHistoryItemId,
        incoming: historyItemId,
      })
      this.compareVersionIsActive = false
    },
    handleClickCompareVersions(): void {
      if (this.compareVersionIsActive) {
        this.selectedHistoryItemId = this.model?.id || null
        this.secondarySelectedHistoryItemId = null
      } else {
        this.secondarySelectedHistoryItemId = null
      }
      this.compareVersionIsActive = !this.compareVersionIsActive
    },
    async loadItem(): Promise<void> {
      removeActiveLoad()
      this.$emit('loadAndRenderItem', {
        id: this.selectedHistoryItemId,
        drawCallback: async () => {
          await drawModelView(
            this.$refs.canvas as HTMLCanvasElement,
            this.$refs.canvasParent as HTMLDivElement,
            this.$vuetify.theme.dark
          )
          setGuiObjects() //TODO RECONSIDER
        },
      })
    },
    async handleHistoryItemSelect(historyItemId: string): Promise<void> {
      this.selectedHistoryItemId = historyItemId
      this.$emit('historyItemSelect', historyItemId)
      const historyId = historyItemId === this.$route.params.modelId ? undefined : historyItemId

      await fetchComments(this.getModelId, historyId)

      const relevantSupplyOptimization = await getSupplyOptimization(historyId)
      setSupplyOptimization(relevantSupplyOptimization || null)
    },
    async handleHistoryItemCreate({
      title,
      description,
    }: {
      title: string
      description: string
    }): Promise<void> {
      const steelspaceHistoryItem = await createHistoryItem(
        this.model as SteelspaceModel,
        title,
        description
      )

      if (!steelspaceHistoryItem || !this.onstockProject || !this.onstockModel) {
        console.error('Error occured during creating history item to warehouse optimization')
        return
      }

      await createOnStockHistoryItem(
        this.onstockProject.id.toString(),
        this.onstockModel.id.toString(),
        steelspaceHistoryItem.id
      )

      await fetchModelHistoryItemsById(this.onstockModel.steelspaceId)
      await fetchOnStockModelBySteelspaceId(this.$route.params.modelId) //to update state
    },
    resetCamera,
    isometricCamera,
  },
  async mounted(): Promise<void> {
    const context = new RenderContext(
      this.$refs.canvas as HTMLCanvasElement,
      this.$refs.canvasParent as HTMLDivElement
    )
    context.RenderGizmo(getGizmoColorsBasedOnTheme(this.$vuetify.theme.dark))
    setRenderContext(context)
    await fetchOnStockUsers()
    await fetchComments(this.getModelId, this.$route.params.historyId)
    await setFloatingComments()
  },
  created(): void {
    this.selectedHistoryItemId = this.$route.params.historyId || this.$route.params.modelId || null
  },
  watch: {
    async commentSelectType() {
      await setFloatingComments()
    },
    async selectedHistoryItemId() {
      clearView()
      deleteLabels()
      await this.loadItem()

      this.$emit('input', this.selectedItem)
    },
    async model(newModel: SteelspaceModel, oldModel: SteelspaceModel) {
      if (
        newModel?.id === oldModel?.id &&
        this.selectedHistoryItemId === this.model?.id &&
        newModel.checksum !== oldModel.checksum
      ) {
        await this.loadItem()
      }
    },
    unselectedMembersToTransparentOption() {
      if (this.unselectedMembersToTransparentOption) setIsolation(modelViewerStore.selectedIds)
      else setIsolation([])
    },
  },
})
